<template>
  <CommonsModalsModalBody>
    <template #title>
      <span>{{ idVehicle ? "Modifier" : "Ajouter" }} un véhicule</span>
    </template>

    <template #help>
      {{ help }}
    </template>

    <template #content>
      <div class="modal-content">
        <div class="nv-form">
          <CommonsDropzoneContainer
            :extensions="basicImageAndPdfMimeType"
            :max-files="1"
            :max-size="2"
            :show-drop-box-button="true"
            @updated="files = $event"
          />

          <div class="row-two-item">
            <uds-input-checkbox
              label="Véhicule personnel"
              :is-checked="form.isPerso"
              @input="form.isPerso = $event"
            />
            <uds-input-checkbox
              label="Véhicule de location"
              :is-checked="form.isLeasing"
              @input="form.isLeasing = $event"
            />
          </div>
          <div class="row-two-item">
            <uds-input-select-auto-popper
              label="Type de véhicule"
              :value="form.idTypeVehicle"
              is-required
              :items="vehicleTypes"
              item-value="id"
              item-text="label"
              :error="err.idTypeVehicle"
              @select="form.idTypeVehicle = $event || null"
            />
            <uds-input-string
              label="Immatriculation"
              :tooltip-text="`case A de la carte grise`"
              is-required
              :value="form.registration"
              :error="err.registration"
              @change="form.registration = $event || null"
            />
          </div>
          <div class="row-two-item">
            <uds-input-string
              label="Marque"
              is-required
              :tooltip-text="`case D1 de la carte grise`"
              :value="form.carBrand"
              :error="err.carBrand"
              @change="form.carBrand = $event || null"
            />
            <uds-input-string
              label="Modèle"
              is-required
              :value="form.name"
              :error="err.name"
              @change="form.name = $event || null"
            />
          </div>
          <div class="row-two-item">
            <uds-input-select-auto-popper
              label="Motorisation"
              is-required
              :value="form.idFuel"
              :items="fuelTypes"
              item-value="value"
              item-text="text"
              :tooltip-text="`case P3 de la carte grise`"
              :error="err.idFuel"
              @select="form.idFuel = $event || null"
            />
            <uds-input-select-auto-popper
              label="Chevaux fiscaux"
              is-required
              :value="form.power"
              :items="powerList"
              item-value="value"
              item-text="text"
              :error="err.power"
              @select="form.power = $event || null"
            />
          </div>
          <div class="row-two-item">
            <uds-input-calendar
              label="Date de première mise en circulation"
              is-required
              :date="form.dateOfFirstRegistration"
              :error="err.dateOfFirstRegistration"
              tooltip-text="case B de la carte grise"
              @change-date="form.dateOfFirstRegistration = $event"
            />
            <uds-input-string
              label="Taux d'émission"
              is-required
              :tooltip-text="`case V7 de la carte grise`"
              :value="form.emissionRate"
              :error="err.emissionRate"
              @change="form.emissionRate = $event || null"
            />
          </div>
          <div class="row-two-item">
            <uds-input-select-auto-popper
              label="Genre"
              is-required
              :tooltip-text="`case J1 de la carte grise`"
              :value="form.carType"
              :error="err.carType"
              :items="carTypeList"
              item-value="value"
              item-text="text"
              @select="form.carType = $event || null"
            />

            <uds-input-select-auto-popper
              label="Type de carrosserie"
              is-required
              :tooltip-text="`case J3 de la carte grise`"
              :value="form.sheetMetalType"
              :error="err.sheetMetalType"
              :items="sheetMetalTypeList"
              item-value="value"
              item-text="text"
              @select="form.sheetMetalType = $event || null"
            />
          </div>
          <div class="nv-row">
            <uds-input-select-auto-popper
              label="Collaborateur"
              is-required
              :value="form.idSocietyPartner"
              :items="formattedPartners"
              item-value="idSocietyPartner"
              item-text="formattedName"
              :error="err.idSocietyPartner"
              @select="form.idSocietyPartner = $event || null"
            />
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <uds-main-button type="tertiary" size="small" @click="$emit('cancel')"
        >Annuler</uds-main-button
      >
      <uds-main-button
        size="small"
        :disabled="!isValid"
        :loading="isLoading"
        @click="addNewVehicle()"
      >
        Enregistrer
      </uds-main-button>
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
// the type of the form is the type of the payload where any property can be null, without the property "cardFile"
import type {
  CarTypeList,
  ISocietyPartner,
  ISocietyVehicle,
  SheetMetalTypeList,
  TypeVehicle,
  VehiclePowerList,
  FuelTypeList,
} from "@silexpert/core";
import {
  PartnerType,
  sheetMetalTypeList as listSheetMetalType,
  vehiclePowerList as listVehiclePower,
  fuelTypeList as listFuelType,
  carTypeList as listCarType,
  VehicleType,
} from "@silexpert/core";

type PostSocietyVehiclePayloadForm = Omit<
  {
    [Property in keyof ISocietyVehicle]: ISocietyVehicle[Property] | null;
  },
  "cardFile" | "idSociety" | "idFile" | "file" | "societyPartner" | "fuel" | "typeVehicle"
>;

const emit = defineEmits(["cancel", "close"]);

// @Prop({ validator: () => true, required: false, default: [] })
// droppedFiles!: File[];

// @Watch("droppedFiles")
// handleDroppedFiles(files: File[]) {
//   this.handleSelectedFiles(files);
// }

const props = defineProps<{
  fromIk?: boolean;
  idVehicle: number | null;
  help: string;

  // droppedFiles: File[];
}>();

const partnerStore = usePartnerStore();
const societyStore = useSocietyStore();
const vehicleStore = useVehicleStore();

const form = ref<PostSocietyVehiclePayloadForm>({
  name: null,
  isPerso: false,
  power: null,
  idSocietyPartner: null,
  idFuel: null,
  idTypeVehicle: null,
  isLeasing: false,
  registration: null,
  carBrand: null,
  carType: null,
  sheetMetalType: null,
  dateOfFirstRegistration: null,
  emissionRate: null,
});

const files = ref<File[]>([]);
const isLoading = ref(false);

const partners = computed<ISocietyPartner[]>(() => {
  return partnerStore.getPartnersForAnnotating;
});

const carTypeList = computed<CarTypeList>(() => {
  return listCarType;
});

const sheetMetalTypeList = computed<SheetMetalTypeList>(() => {
  return listSheetMetalType;
});

const powerList = computed<VehiclePowerList>(() => {
  return listVehiclePower;
});

const formattedPartners = computed<{ idSocietyPartner: number; formattedName: string }[]>(() => {
  return partners.value.map((p) => {
    return {
      idSocietyPartner: p.id!,
      formattedName:
        p.idPartnerType === PartnerType.MORAL_PARTNER
          ? `${p.company}`
          : `${p.firstname} ${p.lastname}`,
    };
  });
});

const fuelTypes = computed<FuelTypeList>(() => {
  return listFuelType;
});

const vehicleTypes = computed<TypeVehicle[]>(() => {
  return Object.values(VehicleType);
});

const err = computed<{ [key: string]: string | null }>(() => {
  const {
    name,
    power,
    idTypeVehicle,
    idFuel,
    idSocietyPartner,
    carType,
    carBrand,
    emissionRate,
    dateOfFirstRegistration,
    sheetMetalType,
    registration,
  } = form.value;

  return {
    name: !isDefined(name) ? "Le champ est requis." : null,
    power: !isDefined(power) ? "Le champ est requis." : null,
    idTypeVehicle: !isDefined(idTypeVehicle) ? "Le champ est requis." : null,
    idFuel: !isDefined(idFuel) ? "Le champ est requis." : null,
    idSocietyPartner: !isDefined(idSocietyPartner) ? "Le champ est requis." : null,
    carType: !isDefined(carType) ? "Le champ est requis." : null,
    carBrand: !isDefined(carBrand) ? "Le champ est requis." : null,
    emissionRate: !isDefined(emissionRate) ? "Le champ est requis." : null,
    dateOfFirstRegistration: !isDefined(dateOfFirstRegistration) ? "Le champ est requis." : null,
    sheetMetalType: !isDefined(sheetMetalType) ? "Le champ est requis." : null,
    registration: !isDefined(registration) ? "Le champ est requis." : null,
    file: !(files.value.length > 0) && !props.idVehicle ? "Un fichier est requis." : null,
  };
});

const isValid = computed(() => {
  for (const property in err.value) {
    const value = err.value[property];
    if (isDefined(value)) {
      return false;
    }
  }
  return true;
});

const idSociety = computed(() => societyStore?.society?.id ?? 0);

async function addNewVehicle() {
  isLoading.value = true;

  const payload = {
    name: form.value.name ?? undefined,
    isPerso: form.value.isPerso ?? undefined,
    power: form.value.power ?? undefined,
    idSocietyPartner: form.value.idSocietyPartner ?? undefined,
    idFuel: form.value.idFuel ?? undefined,
    idTypeVehicle: form.value.idTypeVehicle ?? undefined,
    isLeasing: form.value.isLeasing,
    registration: form.value.registration,
    carBrand: form.value.carBrand,
    carType: form.value.carType,
    sheetMetalType: form.value.sheetMetalType,
    dateOfFirstRegistration: form.value.dateOfFirstRegistration,
    emissionRate: form.value.emissionRate,
  };

  if (props.idVehicle) {
    try {
      await $silex().vehicle.update(idSociety.value, props.idVehicle, payload);
      emit("close");
      $notifier().open({ type: "success", content: "Véhicule mis à jour" });
    } catch (error) {
      $notifier().open({ type: "error", content: apiErrorToString(error) });
    } finally {
      isLoading.value = false;
      await Promise.all([
        vehicleStore.fetchSocietyVehicles(),
        vehicleStore.fetchIfVehiclesAreFullyFilled(),
      ]);
    }
  } else {
    try {
      const newVehicle = await $silex().vehicle.create(idSociety.value, payload, files.value[0]);
      const newVehicleId = newVehicle?.id ?? null;
      await Promise.all([
        vehicleStore.fetchSocietyVehicles(),
        vehicleStore.fetchIfVehiclesAreFullyFilled(),
      ]);
      emit("close", newVehicleId);
      $notifier().open({ type: "success", content: "Véhicule enregistré" });
    } catch (error) {
      $notifier().open({ type: "error", content: apiErrorToString(error) });
    } finally {
      isLoading.value = false;
      await vehicleStore.fetchSocietyVehicles();
    }
  }
}

onMounted(async () => {
  if (props.idVehicle) {
    const vehicle = await $silex().vehicle.get(idSociety.value, props.idVehicle);
    Object.assign(form.value, { ...vehicle, isLeasing: vehicle.isLeasing ?? false });
  }
});
</script>

<style lang="scss" scoped>
.modal-content {
  .nv-form {
    flex-grow: 1;

    .row-two-item {
      margin-top: 15px;
      display: flex;
      > * {
        flex-basis: 100%;
        flex-grow: 1;
        &:not(:first-child) {
          margin-left: 15px;
        }
      }
    }

    .nv-row {
      display: flex;
      &:not(:first-child) {
        margin-top: 15px;
      }

      > * {
        flex-basis: 100%;
        flex-grow: 1;
        &:not(:first-child) {
          margin-left: 15px;
        }
      }
    }
  }
}
</style>
